// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Lato", sans-serif;
}

.main-head {
  height: 150px;
  background: #FFF;
}

.sidenav {
  height: 100%;
  background: linear-gradient(to right, #00796B, #FFC107); /* Gradiente da imagem */
  overflow-x: hidden;
  padding-top: 20px;
}

.main {
  padding: 0px 10px;
}

@media screen and (max-height: 450px) {
  .sidenav {
      padding-top: 15px;
  }
}

@media screen and (max-width: 450px) {
  .login-form {
      margin-top: 10%;
  }

  .register-form {
      margin-top: 10%;
  }
}

@media screen and (min-width: 768px) {
  .main {
      margin-left: 40%;
  }

  .sidenav {
      width: 40%;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
  }

  .login-form {
      margin-top: 80%;
  }

  .register-form {
      margin-top: 20%;
  }
}

.login-main-text {
  margin-top: 20%;
  padding: 60px;
  color: #fff;
  text-align: center;
}

.logos-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.login-main-text h2 {
  font-weight: 300;
}

.logo {
  width: 150px;
  height: auto;
}

.btn-black {
  background-color: #000 !important;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Login.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,uDAAuD,EAAE,wBAAwB;EACjF,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;MACI,iBAAiB;EACrB;AACF;;AAEA;EACE;MACI,eAAe;EACnB;;EAEA;MACI,eAAe;EACnB;AACF;;AAEA;EACE;MACI,gBAAgB;EACpB;;EAEA;MACI,UAAU;MACV,eAAe;MACf,UAAU;MACV,MAAM;MACN,OAAO;EACX;;EAEA;MACI,eAAe;EACnB;;EAEA;MACI,eAAe;EACnB;AACF;;AAEA;EACE,eAAe;EACf,aAAa;EACb,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,iCAAiC;EACjC,WAAW;AACb","sourcesContent":["body {\n  font-family: \"Lato\", sans-serif;\n}\n\n.main-head {\n  height: 150px;\n  background: #FFF;\n}\n\n.sidenav {\n  height: 100%;\n  background: linear-gradient(to right, #00796B, #FFC107); /* Gradiente da imagem */\n  overflow-x: hidden;\n  padding-top: 20px;\n}\n\n.main {\n  padding: 0px 10px;\n}\n\n@media screen and (max-height: 450px) {\n  .sidenav {\n      padding-top: 15px;\n  }\n}\n\n@media screen and (max-width: 450px) {\n  .login-form {\n      margin-top: 10%;\n  }\n\n  .register-form {\n      margin-top: 10%;\n  }\n}\n\n@media screen and (min-width: 768px) {\n  .main {\n      margin-left: 40%;\n  }\n\n  .sidenav {\n      width: 40%;\n      position: fixed;\n      z-index: 1;\n      top: 0;\n      left: 0;\n  }\n\n  .login-form {\n      margin-top: 80%;\n  }\n\n  .register-form {\n      margin-top: 20%;\n  }\n}\n\n.login-main-text {\n  margin-top: 20%;\n  padding: 60px;\n  color: #fff;\n  text-align: center;\n}\n\n.logos-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 20px;\n  margin-bottom: 20px;\n}\n\n.login-main-text h2 {\n  font-weight: 300;\n}\n\n.logo {\n  width: 150px;\n  height: auto;\n}\n\n.btn-black {\n  background-color: #000 !important;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
