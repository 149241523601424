// index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './styles/Login.css';

// Ignorar o erro de ResizeObserver
const resizeObserverErr = function() {
  const originalError = console.error;
  console.error = function(err) {
    if (err.message.includes('ResizeObserver loop limit exceeded')) {
      return;
    }
    originalError.apply(console, arguments);
  };
};

resizeObserverErr();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
