// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/PowerBI.css */
.powerbi-container {
  background-color: #2e2e2e; /* Cor de fundo chumbo */
  height: 100vh; /* Altura da tela toda */
  display: flex;
  justify-content: center;
  align-items: center;
}

.powerbi-container iframe {
  position: absolute;
  top: 0;
  left: 20;
  width: 100%;
  height: 100%;
  border: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PowerBI.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,yBAAyB,EAAE,wBAAwB;EACnD,aAAa,EAAE,wBAAwB;EACvC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,YAAY;AACd","sourcesContent":["/* src/styles/PowerBI.css */\n.powerbi-container {\n  background-color: #2e2e2e; /* Cor de fundo chumbo */\n  height: 100vh; /* Altura da tela toda */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.powerbi-container iframe {\n  position: absolute;\n  top: 0;\n  left: 20;\n  width: 100%;\n  height: 100%;\n  border: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
