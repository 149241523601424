// src/services/auth.js
export async function signIn(username, password) {
  const url = 'https://backendvale.inpressoficina.com.br/api/login/';
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.detail || 'Login failed');
  }

  const data = await response.json();
  return {
    token: data.token,
  };
}
