import axios from 'axios';

export async function signIn(username, password) {
  const url = 'https://vale.oficina.ci/api/login/';
  try {
    const response = await axios.post(url, { username, password }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return { token: response.data.token };
  } catch (error) {
    console.error("Erro durante a autenticação:", error);
    throw new Error(error.response?.data?.detail || 'Login failed');
  }
}
